<template>
  <div>
    <LoadingSpinner v-if="loadingCustomer" variant="success" class="m-auto" />
    <b-form @submit.stop.prevent="onSubmit" v-else>
      <TextInput
        ref="firstName"
        label="First Name"
        name="first-name"
        v-model="firstName"
        :required="true"
        :disabled="!canEdit"
      />
      <TextInput
        ref="lastName"
        label="Last Name"
        name="last-name"
        v-model="lastName"
        :required="true"
        :disabled="!canEdit"
      />

      <b-form-group label="Address" label-for="input-address" :invalid-feedback="veeErrors.first('input-address')">
        <b-form-input
          name="input-address"
          v-model="address"
          v-validate="{ min: 3 }"
          :state="validateState('input-address')"
          aria-describedby="input-address-feedback"
          data-vv-as="address"
          :disabled="!canEdit"
          trim
        />
      </b-form-group>

      <b-form-group label="City" label-for="input-city" :invalid-feedback="veeErrors.first('input-city')">
        <b-form-input
          name="input-city"
          v-model="city"
          v-validate="{ min: 3 }"
          :state="validateState('input-city')"
          aria-describedby="input-city-feedback"
          data-vv-as="city"
          :disabled="!canEdit"
          trim
        />
      </b-form-group>

      <CountrySelect v-if="country" v-model="country" required />

      <ProvinceDropdownInput v-if="country" v-model="provinceState" :country="country" :label="dropdownLabel" />

      <PostalInput
        v-if="country === 'CA'"
        ref="postalCode"
        label="Postal Code"
        name="postal-code"
        v-model="postalZip"
        :province="provinceState"
        :disabled="!canEdit"
      />

      <ZipInput
        v-if="country === 'US'"
        ref="zipCode"
        label="Zip Code"
        name="zip-code"
        v-model="postalZip"
        :province="provinceState"
        :disabled="!canEdit"
      />

      <b-form-group
        label="Phone Number"
        label-for="input-phone-number"
        :invalid-feedback="veeErrors.first('input-phone-number')"
      >
        <vue-phone-number-input
          id="input-phone-number"
          name="input-phone-number"
          v-model="phone"
          default-country-code="CA"
          no-country-selector
          no-example
          v-validate="{ phoneNumber: true }"
          :state="validateState('input-phone-number')"
          valid-color="#28a745"
          error-color="#dc3545"
          placeholder=""
          no-validator-state
          maxLength="14"
          :class="[
            { 'is-invalid': validateState('input-phone-number') === false },
            { 'is-valid': validateState('input-phone-number') === true }
          ]"
        />
      </b-form-group>

      <b-form-group label="Player Number" label-for="input-player-number" v-if="playerNumber">
        <b-form-input name="input-player-number" v-model="playerNumber" :disabled="true" />
      </b-form-group>

      <EmailInput ref="email" label="Email" name="email" v-model="email" :disabled="!canEdit" />
      <b-form-group label="Comment" label-for="input-comments" :invalid-feedback="veeErrors.first('input-comments')">
        <b-form-textarea
          :disabled="!canEdit"
          name="input-comments"
          v-model="notes"
          placeholder="Enter comment..."
          rows="3"
          v-validate="{ max: 255 }"
          trim
        ></b-form-textarea>
      </b-form-group>

      <b-form-group label="Active" label-for="input-active" label-size="md" label-class="mb-0">
        <b-form-checkbox v-model="active" :state="validateState('input-active')" name="input-active" switch />
      </b-form-group>

      <div class="goldrush-online-container">
        <b-badge variant="primary" v-if="goldrushOnline">Goldrush Online Player</b-badge>
      </div>

      <b-button class="submit" variant="success" @click="updateCustomer" v-if="canEdit">Save</b-button>
    </b-form>
  </div>
</template>

<script>
import TextInput from '@/components/inputs/TextInput';
import EmailInput from '@/components/inputs/EmailInput';
import PostalInput from '@/components/inputs/PostalInput';
import ZipInput from '@/components/inputs/ZipInput.vue';
import CustomerServiceV2 from '@/lib/customer-service-v2';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import { getAuth } from '@rafflebox-technologies-inc/auth-service-sdk';
import ProvinceDropdownInput from '@/components/inputs/ProvinceDropdownInput';
import OrganizationServiceV2 from '@/lib/organization-service-v2';
import _ from 'lodash';
import { unleashFeatureEnabled, UnleashKeys } from '@/lib/unleash';
import LoadingSpinner from '@/components/rbComponents/LoadingSpinner.vue';
import CountrySelect from '@/components/CountrySelect.vue';

export default {
  props: ['customerId'],
  components: {
    TextInput,
    PostalInput,
    EmailInput,
    VuePhoneNumberInput,
    ZipInput,
    ProvinceDropdownInput,
    LoadingSpinner,
    CountrySelect
  },
  data() {
    return {
      customer: {},
      firstName: null,
      lastName: null,
      address: null,
      city: null,
      postalZip: null,
      provinceState: null,
      phone: null,
      email: null,
      notes: null,
      canEdit: false,
      active: false,
      goldrushOnline: false,
      playerNumber: null,
      country: '',
      canEditCustomer: false,
      loadingCustomer: true,
      usZipCode: null,
      caPostalCode: null
    };
  },
  computed: {
    dropdownLabel() {
      return this.country === 'CA' ? 'Province' : 'State';
    }
  },
  async mounted() {
    this.canEditCustomer = await unleashFeatureEnabled(UnleashKeys.EditCustomer);

    if (this.canEditCustomer) {
      this.canEdit = true;
    }
  },
  async created() {
    await this.resetForm();

    if (!this.country) {
      const organizationId = (await getAuth().sessionUser()).organizationUuid;
      const organization = await OrganizationServiceV2.retrieveOrganization(organizationId);
      this.country = organization.country;
    }
  },
  watch: {
    country(newCountry, oldCountry) {
      this.handleCountryChange(newCountry, oldCountry);
    }
  },
  provide() {
    return { parentValidator: this.$validator };
  },
  methods: {
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    validate() {
      const lastName = this.$refs.lastName.isValid();
      const firstName = this.$refs.firstName.isValid();
      const email = this.$refs.email.isValid();
      const postal = this.country === 'CA' ? this.$refs.postalCode.isValid() : this.$refs.zipCode.isValid();

      return firstName && lastName && email && postal;
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    async resetForm() {
      this.loadingCustomer = true;
      this.customer = await CustomerServiceV2.retrieveCustomer(this.customerId);

      this.firstName = this.customer.firstName;
      this.lastName = this.customer.lastName;
      this.address = this.customer.address;
      this.city = this.customer.city;
      this.postalZip = this.customer.postal;
      this.provinceState = this.customer.province;
      this.phone = this.customer.phone ? this.formatPhone(this.customer.phone) : null;
      this.email = this.customer.email;
      this.notes = this.customer?.comments;
      this.active = this.customer.active;
      this.goldrushOnline = this.customer.goldrushOnline;
      this.playerNumber = this.customer.playerNumber;

      const organizationId = (await getAuth().sessionUser()).organizationUuid;
      const organization = await OrganizationServiceV2.retrieveOrganization(organizationId);

      if (this.customer.postal) {
        // Check if it's a US ZIP code (either 5 digits or 9 digits with a hyphen)
        if (/^\d{5}(-\d{4})?$/.test(this.customer.postal)) {
          this.country = 'US';
        }
        // Check if it's a Canadian postal code (alphanumeric format)
        else if (/^[A-Za-z]\d[A-Za-z][ ]?\d[A-Za-z]\d$/.test(this.customer.postal)) {
          this.country = 'CA';
        }
        // If neither, use the country provided by the customer
        else {
          this.country = this.customer.country;
        }
      } else {
        // If no postal code is provided, default to the organization's country
        this.country = organization.country;
      }

      // Store initial postal/zip codes for each country
      if (this.country === 'US') {
        this.usZipCode = this.postalZip;
      } else if (this.country === 'CA') {
        this.caPostalCode = this.postalZip;
      }

      this.loadingCustomer = false;
      this.$nextTick(() => {
        this.$validator.reset();
      });
    },
    handleCountryChange(newCountry, oldCountry) {
      this.saveCurrentPostalCode(oldCountry);
      this.restorePostalCodeForNewCountry(newCountry);
    },
    saveCurrentPostalCode(country) {
      if (country === 'US') {
        this.usZipCode = this.postalZip;
      } else if (country === 'CA') {
        this.caPostalCode = this.postalZip;
      }
    },
    restorePostalCodeForNewCountry(country) {
      if (country === 'US') {
        this.postalZip = this.usZipCode;
      } else if (country === 'CA') {
        this.postalZip = this.caPostalCode;
      } else {
        this.postalZip = null;
      }
    },
    async updateCustomer() {
      const customValid = this.validate();
      const valid = await this.$validator.validateAll();
      if (!valid || !customValid) {
        return;
      }

      const updatedCustomer = { country: this.country };

      if (this.firstName !== this.customer.firstName) {
        updatedCustomer.firstName = this.firstName;
      }

      if (this.lastName !== this.customer.lastName) {
        updatedCustomer.lastName = this.lastName;
      }

      if (this.address !== this.customer.address) {
        updatedCustomer.address = this.address;
      }

      if (this.city !== this.customer.city) {
        updatedCustomer.city = this.city;
      }

      if (this.postalZip !== this.customer.postal) {
        updatedCustomer.postalZip = this.postalZip.replace(/\s+/g, '');
      }

      if (this.provinceState !== this.customer.province) {
        updatedCustomer.provinceState = this.provinceState;
      }

      if (this.phone !== this.customer.phone) {
        updatedCustomer.phone = this.phone?.replace(/\D/g, '') || null;
      }

      if (this.email !== this.customer.email) {
        updatedCustomer.email = this.email?.toLowerCase() || null;
      }
      if (this.notes !== this.customer.comments) {
        updatedCustomer.notes = this.notes;
      }
      if (this.notes === '' || this.notes === null) {
        updatedCustomer.notes = '';
      }

      if (updatedCustomer.active !== this.customer.active) {
        updatedCustomer.active = this.active;
      }

      try {
        if (!_.isEmpty(updatedCustomer)) {
          await CustomerServiceV2.updateCustomer(this.customer.id, updatedCustomer);
          this.resetForm();
          this.scrollToTop();
          this.$emit('onEdited', { customer: updatedCustomer });
        }
      } catch (error) {
        const errorMessage = this.parseError(error).message;
        this.scrollToTop();
        this.$emit('onEdited', { errorMessage: errorMessage });
      }
    }
  }
};
</script>

<style lang="scss">
.vue-phone-number-input {
  &:focus {
    outline: none;
  }

  &.is-invalid input {
    border-color: #dc3545 !important;

    &:focus {
      outline: none;
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25) !important;
    }
  }

  &.is-valid input {
    border-color: #28a745 !important;

    &:focus {
      outline: none;
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25) !important;
    }
  }

  &.is-focused input {
    box-shadow: none;
  }

  .input-tel {
    height: 3rem;

    &:focus {
      outline: none;
    }

    label {
      display: none !important;
    }

    input {
      cursor: auto;
      display: block;
      width: 100%;
      height: 2.8rem;
      padding: 0.6rem 1rem !important;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: 0.5rem !important;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

      &:focus {
        outline: none;
        border: 1px solid;
        box-shadow: none;
      }

      &::placeholder,
      &::-webkit-input-placeholder {
        color: transparent;
      }
    }
  }
}

.invalid-feedback-phone {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.goldrush-online-container {
  padding-bottom: 2rem;
}
</style>
