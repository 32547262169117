var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.errorMessage)?_c('Alert',{attrs:{"variant":"red","icon":"exclamation"}},[_vm._v(_vm._s(_vm.errorMessage))]):_vm._e(),_c('b-table',{staticClass:"orders-table",attrs:{"show-empty":"","id":"table","striped":"","sort-icon-left":"","fields":_vm.table.fields,"per-page":_vm.table.perPage,"current-page":_vm.table.currentPage,"items":_vm.items,"sort-by":_vm.table.sortBy,"sort-desc":_vm.table.sortDesc},on:{"update:sortBy":function($event){return _vm.$set(_vm.table, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.table, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.table, "sortDesc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.table, "sortDesc", $event)}},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center"},[_c('b-spinner',{staticClass:"align-middle"})],1)]},proxy:true},{key:"cell(createdAt)",fn:function(row){return [_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"dashline",attrs:{"title":_vm.formatDateTime(_vm.parseISO(row.item.createdAt), {
            format: 'dateTime',
            timeZone: _vm.sessionUserTimeZone,
            displayTimeZone: true
          })}},[_vm._v(" "+_vm._s(_vm.formatDateTime(_vm.parseISO(row.item.createdAt)))+" ")])]}},{key:"cell(eventDrawDate)",fn:function(row){return [_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"dashline",attrs:{"title":_vm.formatDateTime(_vm.parseISO(row.item.eventDrawDate), {
            format: 'dateTime',
            timeZone: _vm.sessionUserTimeZone,
            displayTimeZone: true
          })}},[_vm._v(" "+_vm._s(_vm.formatDateTime(_vm.parseISO(row.item.eventDrawDate)))+" ")])]}},{key:"cell(eventName)",fn:function(row){return [_c('router-link',{attrs:{"to":{ name: 'View Raffle', query: { id: row.item.eventId } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var href = ref.href;
return [_c('a',{attrs:{"href":href}},[_vm._v(_vm._s(row.item.eventName))])]}}],null,true)})]}},{key:"cell(status)",fn:function(data){return [(data.value === 'ACTIVE')?_c('b-badge',{attrs:{"variant":"success"}},[_vm._v("Active")]):(data.value === 'INACTIVE')?_c('b-badge',{attrs:{"variant":"secondary"}},[_vm._v("Inactive")]):(data.value === 'PENDING')?_c('b-badge',{attrs:{"variant":"light"}},[_vm._v("Pending")]):(data.value === 'VOID')?_c('b-badge',{attrs:{"variant":"dark"}},[_vm._v("Void")]):(data.value === 'FAILED')?_c('b-badge',{attrs:{"variant":"danger"}},[_vm._v("Error")]):_vm._e()]}}])}),_c('b-tfoot',[_c('b-tr',[_c('b-td',{staticClass:"text-right",attrs:{"colspan":"7"}},[_vm._v(" Total Rows: "),_c('strong',[_vm._v(" "+_vm._s(_vm.table.rows)+" ")])])],1)],1),_c('b-pagination',{attrs:{"total-rows":_vm.table.rows,"per-page":_vm.table.perPage,"aria-controls":"table","pills":"","align":"center","first-text":"First","prev-text":"<","next-text":">","last-text":"Last"},model:{value:(_vm.table.currentPage),callback:function ($$v) {_vm.$set(_vm.table, "currentPage", $$v)},expression:"table.currentPage"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }